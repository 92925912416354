import { Fragment, h } from 'preact';
import { useMemo } from 'preact/hooks';
import {
  DidomiIcon,
  DidomiDropdown,
  DidomiOption,
} from '@didomi/ui-atoms-react';
import {
  useOrganizations,
  useActiveOrganization,
  useSPARouter,
} from '@didomi/utility-react';

export const OrganizationSelectionButton = () => {
  const { navigateTo } = useSPARouter();
  const [organizations] = useOrganizations();
  const { organizationId, organization, setOrganization } =
    useActiveOrganization();

  const sortedOrganizations = useMemo(() => {
    return organizations?.length
      ? organizations.sort((orgA, orgB) => {
          return orgA.name?.localeCompare(orgB.name);
        })
      : [];
  }, [organizations]);

  const changeOrganization = (e) => {
    const newOrgId = e.detail.value;
    /* istanbul ignore else */
    if (newOrgId !== organizationId) {
      setOrganization(newOrgId);
      // We navigate to the home
      navigateTo('dashboard');
    }
  };

  if (organizations?.length <= 1) {
    return (
      <div class="mr-xs">
        <div data-cy="active-organization-name" class="hidden">
          {organization?.name}
        </div>
      </div>
    );
  }

  return (
    <Fragment>
      <DidomiDropdown
        data-cy="organizations-menu"
        variant="blue"
        distance="xxs"
        distanceX="-xxs"
        for="organization-selection-button"
        onSelectionChanged={changeOrganization}
        selectedValue={organization?.name}
      >
        <div>
          {sortedOrganizations.map((org) => (
            <DidomiOption itemText={org.name} itemValue={org.id} />
          ))}
        </div>
      </DidomiDropdown>
      <button
        id="organization-selection-button"
        class="organization-selection-button ml-xl pl-s pr-xs w-[270px] h-full bg-blue-rightpanel text-white text-body-small rounded-l-full flex items-center"
      >
        <DidomiIcon name="website" />
        <span class="flex-1 ml-xs text-left font-bold truncate">
          {organization?.name}
        </span>
        <div class="organization-selection-button-icon-button w-[25px] h-[25px] bg-white text-primary-blue-6 rounded-full flex justify-center items-center">
          <DidomiIcon
            name="down-on-sm"
            class="rotate-0 transition org-selection-opened:rotate-180"
          />
        </div>
      </button>

      <div data-cy="active-organization-name" class="hidden">
        {organization?.name}
      </div>
    </Fragment>
  );
};
