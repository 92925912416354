import { Fragment, h } from 'preact';
import {
  DidomiButton,
  DidomiTooltip,
  DidomiIconButton,
  DidomiMenu,
  DidomiMenuItem,
} from '@didomi/ui-atoms-react';
import {
  useAuthMethods,
  useUserProfile,
  useSPARouter,
  useActiveOrganization,
  useSDK,
} from '@didomi/utility-react';
import { useMediaQuery } from '@didomi/helpers-react/hooks';

export const MyProfileMenu = () => {
  const { logout } = useAuthMethods();
  const isSmallScreen = useMediaQuery('(max-width: 800px)');
  const [userProfile] = useUserProfile();
  const { navigateToAbsolute, navigateTo } = useSPARouter();
  const { organizationId } = useActiveOrganization();
  const { showPreferencesBanner } = useSDK();

  return (
    <Fragment>
      <div id="menu-profile-button">
        {isSmallScreen ? (
          <DidomiTooltip content="My account">
            <DidomiIconButton
              data-cy="profile-menu"
              variant="option"
              icon="login"
              title="My account"
            ></DidomiIconButton>
          </DidomiTooltip>
        ) : (
          <DidomiButton data-cy="profile-menu" variant="option">
            My account
          </DidomiButton>
        )}
      </div>
      <DidomiMenu
        for="menu-profile-button"
        menuTitle="My account"
        menuSubTitle={userProfile?.email}
        placement="bottom-end"
        distance="xxs"
      >
        <DidomiMenuItem
          class="mt-xxs!"
          onClick={() =>
            organizationId
              ? navigateTo('/account/settings')
              : navigateToAbsolute('/account/settings')
          }
          data-cy="profile-account-settings"
        >
          Account settings
        </DidomiMenuItem>
        <DidomiMenuItem
          class="mt-xxs!"
          onClick={() => showPreferencesBanner()}
          data-cy="consent-choices"
        >
          Consent choices
        </DidomiMenuItem>
        <DidomiMenuItem
          iconName="logout"
          onClick={() => logout({ federated: userProfile?.isSAML })}
          data-cy="profile-logout"
        >
          Logout
        </DidomiMenuItem>
      </DidomiMenu>
    </Fragment>
  );
};
