import { Fragment, h } from 'preact';
import {
  DidomiButton,
  DidomiMenu,
  DidomiMenuItem,
} from '@didomi/ui-atoms-react';
import { useSPARouter } from '@didomi/utility-react';

export const ProductSettingsMenu = () => {
  const { navigateTo } = useSPARouter();

  return (
    <Fragment>
      <div id="product-settings-button">
        <DidomiButton data-cy="product-settings-menu" variant="option">
          My products
        </DidomiButton>
      </div>
      <DidomiMenu
        for="product-settings-button"
        menuTitle="My products"
        placement="bottom-end"
        distance="xxs"
      >
        <div class="mt-xxs">
          <DidomiMenuItem
            data-cy="settings-menu-users"
            onClick={() => navigateTo('product-settings/authentication/manage')}
          >
            Authentication
          </DidomiMenuItem>
        </div>
      </DidomiMenu>
    </Fragment>
  );
};
