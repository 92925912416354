import { Fragment, h } from 'preact';
import {
  DidomiButton,
  DidomiTooltip,
  DidomiIconButton,
  DidomiMenu,
  DidomiMenuItem,
  DidomiMenuSeparator,
} from '@didomi/ui-atoms-react';
import {
  useSnackbar,
  useSDK,
  useHubspot,
  useUserProfile,
} from '@didomi/utility-react';
import { useMediaQuery } from '@didomi/helpers-react/hooks';
import copy from 'copy-text-to-clipboard';

export const NeedHelpMenu = () => {
  const { displaySnackbar } = useSnackbar();
  const { isVendorEnabled, showNoticeBanner } = useSDK();
  const { initHubspot, showHubspot } = useHubspot();
  const [userProfile] = useUserProfile();
  const isSmallScreen = useMediaQuery('(max-width: 800px)');

  const isSelfService = userProfile?.self_register;

  const copyEmail = () => {
    copy('support@didomi.io');
    displaySnackbar(
      'The Support email address has been copied to the clipboard',
      {
        action: { name: 'Ok', closeInAction: true },
      },
    );
  };

  const openChat = () => {
    if (!isVendorEnabled('c:hubspot')) {
      window['didomiEventListeners']?.push({
        event: 'notice.clickagree',
        listener: () => {
          /* istanbul ignore next */
          initHubspot();
        },
      });
      showNoticeBanner();
    } else {
      showHubspot();
    }
  };

  return (
    <Fragment>
      <div id="menu-help-button">
        {isSmallScreen ? (
          <DidomiTooltip content="Need help?">
            <DidomiIconButton
              data-cy="help-menu"
              variant="option"
              icon="help"
              title="Need help?"
            ></DidomiIconButton>
          </DidomiTooltip>
        ) : (
          <DidomiButton variant="option">Need help?</DidomiButton>
        )}
      </div>
      <DidomiMenu
        for="menu-help-button"
        menuTitle="Need help?"
        placement="bottom-end"
        distance="xxs"
      >
        <div class="mt-xxs">
          <DidomiMenuItem
            data-cy="didomi-menu-item-help-center"
            iconName="help"
            onClick={() =>
              window.open('https://support.didomi.io/', '_blank')?.focus()
            }
          >
            Help Center
          </DidomiMenuItem>
          <DidomiMenuItem
            iconName="dev-documentation"
            onClick={() =>
              window.open('https://developers.didomi.io/', '_blank')?.focus()
            }
          >
            Developer Docs
          </DidomiMenuItem>

          {!isSelfService && (
            <DidomiMenuItem iconName="chat" onClick={() => openChat()}>
              Chat with us
            </DidomiMenuItem>
          )}

          <DidomiMenuSeparator />
          <DidomiMenuItem
            data-cy="didomi-menu-item-email"
            iconName="email"
            description="support@didomi.io"
            onClick={copyEmail}
          >
            Send an email
          </DidomiMenuItem>
        </div>
      </DidomiMenu>
    </Fragment>
  );
};
