import { Fragment, h } from 'preact';
import copy from 'copy-text-to-clipboard';

import {
  DidomiButton,
  DidomiMenu,
  DidomiMenuItem,
  DidomiTooltip,
  DidomiIconButton,
} from '@didomi/ui-atoms-react';
import {
  useSnackbar,
  useActiveOrganization,
  useSPARouter,
} from '@didomi/utility-react';
import { useMediaQuery } from '@didomi/helpers-react/hooks';
import { useOrgSettingsMenuVisibility } from '../../hooks/useOrgSettingsMenuVisibility.hook';

export const OrganizationSettingsMenu = () => {
  const { displaySnackbar } = useSnackbar();
  const { navigateTo } = useSPARouter();
  const { organization } = useActiveOrganization();
  const isSmallScreen = useMediaQuery('(max-width: 800px)');

  const {
    withUsers,
    withSSO,
    withPrivateApiKey,
    withPublicApiKey,
    withOrgSettings,
  } = useOrgSettingsMenuVisibility();
  const isMenuEmpty =
    !withUsers &&
    !withSSO &&
    !withPrivateApiKey &&
    !withPublicApiKey &&
    !withOrgSettings;

  const copyPublicAPI = () => {
    copy(organization?.public_api_key);
    displaySnackbar('Public API key has been copied', {
      action: { name: 'Ok', closeInAction: true },
    });
  };

  if (isMenuEmpty) {
    return null;
  }

  return (
    <Fragment>
      <div id="menu-settings-button">
        {isSmallScreen ? (
          <DidomiTooltip content="My organization">
            <DidomiIconButton
              data-cy="settings-menu"
              variant="option"
              icon="settings"
              title="My organization"
            ></DidomiIconButton>
          </DidomiTooltip>
        ) : (
          <DidomiButton data-cy="settings-menu" variant="option">
            My organization
          </DidomiButton>
        )}
      </div>

      <DidomiMenu
        for="menu-settings-button"
        menuTitle="My organization"
        placement="bottom-end"
        distance="xxs"
        actionName={withPublicApiKey ? 'Copy Public API key' : null}
        onActionClick={copyPublicAPI}
      >
        <div class="mt-xxs">
          {withOrgSettings && (
            <DidomiMenuItem
              class="mb-xxs!"
              data-cy="settings-menu-organization-settings"
              onClick={() => navigateTo('/organization/settings')}
            >
              Settings
            </DidomiMenuItem>
          )}
          {withUsers && (
            <DidomiMenuItem
              data-cy="settings-menu-users"
              onClick={() => navigateTo('/organization/users')}
            >
              Users & Roles
            </DidomiMenuItem>
          )}

          {withSSO && (
            <DidomiMenuItem
              data-cy="settings-menu-sso"
              onClick={() => navigateTo('/organization/sso')}
            >
              Single Sign-on
            </DidomiMenuItem>
          )}

          {withPrivateApiKey && (
            <DidomiMenuItem
              data-cy="settings-menu-item-key"
              onClick={() => navigateTo('/organization/keys')}
            >
              Private API key
            </DidomiMenuItem>
          )}
        </div>
      </DidomiMenu>
    </Fragment>
  );
};
