import {
  AvailableModules,
  modules,
  AccessTypes,
} from '@didomi/authorization-metadata';

// Access Policies per Module Access Type
export const ACCESS_POLICIES_CONFIG = {
  USERS_VIEWER:
    modules[AvailableModules.UsersAndRoles].accessTypeAllowedPolicies[
      AccessTypes.Viewer
    ],
  ORG_SETTINGS_VIEWER:
    modules[AvailableModules.OrganizationSettings].accessTypeAllowedPolicies[
      AccessTypes.Viewer
    ],
  ORG_SETTINGS_EDITOR:
    modules[AvailableModules.OrganizationSettings].accessTypeAllowedPolicies[
      AccessTypes.Editor
    ],
};
