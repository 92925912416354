import { h } from 'preact';
import { OrganizationSelectionButton } from '../OrganizationSelectionButton/OrganizationSelectionButton';
import { MyProfileMenu } from '../MyProfileMenu/MyProfileMenu';
import { NeedHelpMenu } from '../NeedHelpMenu/NeedHelpMenu';
import { OrganizationSettingsMenu } from '../OrganizationSettingsMenu/OrganizationSettingsMenu';
import { useMediaQuery } from '@didomi/helpers-react/hooks';
import {
  useActiveOrganization,
  useSidenavMethods,
  useEnvironment,
  useHasAccessPolicies,
} from '@didomi/utility-react';
import { DidomiIconButton } from '@didomi/ui-atoms-react';
import { ProductSettingsMenu } from '../ProductSettingsMenu/ProductSettingsMenu';
import { adminAccessPolicy } from '@didomi/authorization-metadata';

export const Topnav = () => {
  const isSmallScreen = useMediaQuery('(max-width: 768px)');
  const { organization } = useActiveOrganization();
  const { assetsBaseUrl } = useEnvironment();
  const { hasAccess: hasAdminAccess } = useHasAccessPolicies([
    adminAccessPolicy.id,
  ]);

  const {
    isOpen: isSidenavOpen,
    open: openSidenav,
    close: closeSidenav,
  } = useSidenavMethods();

  const toggleSidenav = () => {
    if (isSidenavOpen) {
      closeSidenav();
    } else {
      openSidenav();
    }
  };

  return (
    <div
      id="topnav"
      class="w-full h-[60px] bg-white flex items-center justify-center md:justify-between! border-1 border-solid border-neutral-gray-3"
    >
      {isSmallScreen && (
        <DidomiIconButton
          class="absolute top-[12px] left-[12px] flex items-center justify-center"
          title="Toggle sidenav"
          icon="hamburger-menu"
          variant="rounded"
          onClick={toggleSidenav}
        />
      )}
      <img
        class="md:ml-[32px]"
        src={`${assetsBaseUrl}/Didomi-logotype-new.svg`}
        alt="Didomi logo"
      />
      {!isSmallScreen && (
        <div class="h-full flex items-center justify-end">
          <div class="flex justify-items-center items-center gap-xxs">
            <NeedHelpMenu />
            {hasAdminAccess && <ProductSettingsMenu />}
            {!!organization && <OrganizationSettingsMenu />}
            <MyProfileMenu />
          </div>
          <OrganizationSelectionButton />
        </div>
      )}
    </div>
  );
};
