import { h } from 'preact';
import { connect } from '@didomi/ui-foundation';
import { Topnav } from './components/Topnav/Topnav';

import { UtilityProvider } from '@didomi/utility-react';
import * as utility from '@didomi/utility';

if (process.env.NODE_ENV === 'development') {
  require('preact/debug');
}

// Setup Twind for topbar only
connect('.topnav-root', {
  variants: {
    'org-selection-opened': '#organization-selection-button[data-opened] &',
  },
  theme: {
    extend: {
      backgroundImage: (theme) => ({
        // Use a own gradient
        'blue-rightpanel': `linear-gradient(314.18deg, #1D4153 0%, #276F94 136.67%)`,
      }),
    },
  },
});

export const TopnavApp = () => {
  return (
    <UtilityProvider utility={utility}>
      <Topnav />
    </UtilityProvider>
  );
};
